<template>
    <div>
        <ts-page-title
            :title="$t('historyLog.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('historyLog.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="row tw-w-1/3">
                        <div class="col-md-6">
                            <label class="form-col-label control-label">{{
                                $t('historyLog.dateRange')
                            }}</label>
                            <a-range-picker
                                v-model="dateRange"
                                :allowClear="false"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                @change="fetchData()"
                            />
                        </div>
                        <div class="col-md-6">
                            <label class="form-col-label control-label">{{
                                $t('historyLog.user')
                            }}</label>
                            <a-select
                                class="tw-w-full"
                                mode="multiple"
                                placeholder="All"
                                :loading="search_loading"
                                :filterOption="
                                    (input, option) =>
                                        option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                "
                                v-model="model.user_id"
                                :options="usersList"
                                @search="handleSearchUser"
                            ></a-select>
                        </div>
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('historyLog.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('historyLog.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-auto">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td class="tw-whitespace-nowrap">
                            {{ record.created_date }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.user_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.sys_module }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.sub_module }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.action_type }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.reference_no }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.ref_booking_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.action_detail }}
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { Errors } from 'form-backend-validation'

export default {
    name: 'historyLog',
    components: {},
    data () {
        return {
            showForm: false,
            loading: false,
            search_loading: false,
            show_update_form: false,
            show_form_checked: false,
            model: {
                user_id: []
            },
            errors: new Errors()
        }
    },
    computed: {
        ...mapState('security/historyLog', [
            'resources',
            'pagination',
            'formModels'
        ]),
        columns () {
            return [
                {
                    name: this.$t('historyLog.date'),
                    style: 'width:30px'
                },
                { name: this.$t('historyLog.createdBy') },
                { name: this.$t('historyLog.sysModule') },
                { name: this.$t('historyLog.subModule') },
                { name: this.$t('historyLog.actionType') },
                { name: this.$t('historyLog.deliveryNumber') },
                { name: this.$t('historyLog.bookingNumber') },
                { name: this.$t('historyLog.actionDetail') }
            ]
        },
        usersList () {
            if (this.formModels['users']) {
                return this.formModels['users'].map(el => ({
                    value: el.user_id,
                    label: el.user_name
                }))
            }
            return []
        },
        search: {
            get () {
                return this.$store.state.security.historyLog.search
            },
            set (newValue) {
                this.$store.commit('security/historyLog/SET_SEARCH', newValue)
                this.$store.commit('security/historyLog/RESET_CURRENT_PAGE')
            }
        },
        dateRange: {
            get () {
                return this.$store.state.security.historyLog.dateRange
            },
            set (newValue) {
                this.$store.commit(
                    'security/historyLog/SET_DATE_RANGE',
                    newValue
                )
            }
        }
    },
    methods: {
        ...mapActions('security/historyLog', ['getFormViewData']),
        async fetchFormData () {
            this.loading = true
            await this.getFormViewData({ params: {}, index: undefined })
            this.loading = false
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('security/historyLog/fetch', {
                    ...attributes,
                    user_id: this.model.user_id
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$notify({ type: 'error', text: error.message })
                })
        },
        handleSearchUser: debounce(async function (value) {
            if (!value) return false
            this.search_loading = true
            await this.getFormViewData({
                index: 'users',
                params: {
                    fnName: 'users',
                    user_name: value
                }
            })
            this.search_loading = false
        }, 500)
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        'model.user_id': debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
            vm.fetchFormData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('security/historyLog/RESET_STATE')
        next()
    }
}
</script>
