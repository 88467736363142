var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('historyLog.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('historyLog.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"row tw-w-1/3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('historyLog.dateRange')))]),_c('a-range-picker',{attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('historyLog.user')))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"mode":"multiple","placeholder":"All","loading":_vm.search_loading,"filterOption":function (input, option) { return option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0; },"options":_vm.usersList},on:{"search":_vm.handleSearchUser},model:{value:(_vm.model.user_id),callback:function ($$v) {_vm.$set(_vm.model, "user_id", $$v)},expression:"model.user_id"}})],1)]),_c('div',{staticClass:"tw-space-x-3"},[_c('a-tooltip',{attrs:{"title":_vm.$t('historyLog.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('historyLog.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('div',{staticClass:"tw-overflow-auto"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var record = ref.record;
return [_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.created_date)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.user_name)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.sys_module)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.sub_module)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.action_type)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.reference_no)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.ref_booking_number)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.action_detail)+" ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }